@import '../configs/color.css';

.btn {
    border: 1px solid transparent;
    border-radius: 15px !important;
    padding: 0.4rem 2rem !important;
    line-height: 1.125 !important;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
    justify-content: center !important;
    z-index: 1000 !important;
    font-size: 16px;

    @media (max-width: $md) {
        font-size: 13px !important;
    }
}

/* Botones principales */

.btn-verde {
    color: white !important;
    border-color: $verde !important;
    background-color: $verde !important;
    box-shadow: none;
}

.btn-verde:hover {
    color: #fff !important;
    background-color: $verde !important;
    border-color: $verde !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-verde.focus,
.btn-verde:focus {
    color: $verde !important;
    background-color: white !important;
    border-color: $verde !important;
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
        0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-azul {
    color: white !important;
    border-color: $azul !important;
    background-color: $azul !important;
    box-shadow: none;
}

.btn-azul:hover {
    color: $negro !important;
    background-color: white !important;
    border-color: $azul !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-azul.focus,
.btn-azul:focus {
    color: $negro !important;
    background-color: white !important;
    border-color: $azul !important;
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
        0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-negro {
    color: white !important;
    border-color: $negro32 !important;
    background-color: $negro32 !important;
    box-shadow: none;
}

.btn-negro:hover {
    color: $negro !important;
    background-color: white !important;
    border-color: $negro32 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-negro.focus,
.btn-negro:focus {
    color: $negro !important;
    background-color: white !important;
    border-color: $negro32 !important;
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
        0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-primary {
    color: white !important;
    border-color: $rojo0a !important;
    background-color: $rojo0a !important;
    box-shadow: none;
}

.btn-primary:hover {
    color: $negro !important;
    background-color: white !important;
    border-color: $rojo0a !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-primary.focus,
.btn-primary:focus {
    color: $negro !important;
    background-color: white !important;
    border-color: $rojo0a !important;
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
        0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-secondary {
    color: #fff !important;
    background-color: $rojo45 !important;
    border-color: $rojo45 !important;
    box-shadow: none;
}

.btn-secondary:hover {
    color: $negro !important;
    background-color: white !important;
    border-color: $rojo45 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(90, 97, 105, 0.25);
}

.btn-secondary.focus,
.btn-secondary:focus {
    color: $negro !important;
    background-color: white !important;
    border-color: $rojo45 !important;
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
        0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-gris {
    color: #fff !important;
    background-color: $gris !important;
    border-color: $gris !important;
    box-shadow: none;
}

.btn-gris:hover {
    color: $negro !important;
    background-color: white !important;
    border-color: $gris !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(90, 97, 105, 0.25);
}

.btn-gris.focus,
.btn-gris:focus {
    color: $negro !important;
    background-color: white !important;
    border-color: $gris !important;
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
        0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

.btn-rojo {
    color: #fff !important;
    background-color: $rojo39 !important;
    border-color: $rojo39 !important;
    box-shadow: none;
}

.btn-rojo:hover {
    color: $negro !important;
    background-color: white !important;
    border-color: $rojo39 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(90, 97, 105, 0.25);
}

.btn-rojo.focus,
.btn-rojo:focus {
    color: $negro !important;
    background-color: white !important;
    border-color: $rojo39 !important;
    box-shadow: 0 0 0 3px rgba(90, 97, 105, 0.15),
        0 3px 15px rgba(90, 97, 105, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Botones outline */

.btn-outline-primary {
    background-color: initial !important;
    background-image: none !important;
    border-color: $rojo0a !important;
    color: $rojo0a !important;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: $rojo0a !important;
    border-color: $rojo0a !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15),
        0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-secondary {
    background-color: initial !important;
    background-image: none !important;
    border-color: $rojo45 !important;
    color: $rojo45 !important;
}

.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: $rojo45 !important;
    border-color: $rojo45 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15),
        0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-gris {
    background-color: initial !important;
    background-image: none !important;
    border-color: $gris !important;
    color: $gris !important;
}

.btn-outline-gris:hover {
    color: #fff !important;
    background-color: $gris !important;
    border-color: $gris !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-outline-gris.focus,
.btn-outline-gris:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15),
        0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
}

.btn-outline-rojo {
    background-color: initial !important;
    background-image: none !important;
    border-color: $rojo39 !important;
    color: $rojo39 !important;
}

.btn-outline-rojo:hover {
    color: #fff !important;
    background-color: $rojo39 !important;
    border-color: $rojo39 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
        0 4px 10px rgba(0, 123, 255, 0.25);
}

.btn-outline-rojo.focus,
.btn-outline-rojo:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15),
        0 3px 15px rgba(0, 123, 255, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1) !important;
}
