.icon_svg {
    width: 28px;
    height: 23px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 5px;
    padding-left: 5px;
}

.react-bs-table-container:not(.no-hover) .table tbody tr:hover>td {
    filter: invert(7%) sepia(50%) saturate(8%) hue-rotate(100deg) contrast(100%);
}

.react-bs-table-container:not(.no-hover) .table-jb tbody tr:hover>td:first-child {
    filter: invert(7%) sepia(50%) saturate(8%) hue-rotate(100deg) contrast(100%);
}

.react-bs-table-container.no-hover .table-hover tbody tr:hover {
    color: #000000;
    background-color: rgba(128, 128, 128, 0.425) !important;
}

.react-bs-table-container.no-thead thead {
    display: none !important;
}
