@import '../configs/color.css';

@import '../configs/breakpoints.css';

.card {
    /* overflow: hidden; */
    background-color: $grisd7;
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
}

.card-empty {
    border: none;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    background-color: white;
}

.card-proyecto {
    border: none;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    background-color: white;
}

.card-content-form {
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 3.5rem;
    box-shadow: 0px 0px 7px #00000066 !important;
    border-radius: 15px !important;


    @media (max-width: $md) {
        padding: 1rem;
        padding-bottom: 2.4rem;
        padding-top: 2rem;
    }
}

.card-content-form2 {
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 3.5rem;
    border-radius: 10px;

    @media (max-width: $md) {
        padding: 1rem;
        padding-bottom: 2.4rem;
        padding-top: 2rem;

    }
}

.card-form {
    margin: auto !important;

    max-width: 450px;
    /*
    @media (min-width: $xsm) {
        max-width: 350px;
    }

    @media (min-width: $sm) {
    } */

    @media (min-width: $md) {
        max-width: 500px;
    }

    @media (min-width: $lg) {
        max-width: 2000px;
    }
}

.btn-limit {
    max-width: 250px;

    @media (min-width: $sm) {
        max-width: 200px;
    }

    @media (min-width: $xxxl) {
        max-width: 300px;
    }
}

.padding-custom {
    padding-right: 1rem !important;
    padding-left: 1rem !important;

    @media (min-width: $xxxl) {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
}

.input-search1 {
    max-width: 1000px;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;

    @media (min-width: $sm) {
        max-width: 250px;
        margin-left: auto !important;
    }

    @media (min-width: 1200px) {
        max-width: 1000px;
    }
}

.input-header-1 {
    max-width: 1000px;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;

    @media (min-width: $lg) {
        max-width: 250px;
        margin-left: auto !important;
    }

    @media (min-width: $xxl) {
        max-width: 300px;
        margin-right: auto !important;
    }

    @media (min-width: 1600px) {
        max-width: 400px;
    }
}

.input-header-2 {
    max-width: 1000px;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;

    @media (min-width: $lg) {
        max-width: 250px;
        margin-left: auto !important;
    }

    @media (min-width: $xxl) {
        max-width: 300px;
        margin-left: auto !important;
    }

    @media (min-width: 1600px) {
        max-width: 400px;
    }
}

.espacio1 {
    display: none important;

    @media (min-width: 1200px) {
        display: flex !important;
        flex: 1;
    }
}

.btn-flex {
    display: flex;
    flex: 1;

    @media (min-width: $sm) {
        display: inline;
        flex: auto;
    }

    @media (min-width: $xxl) {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }
}

.btn-flex2 {
    display: flex;
    flex: 1;

    @media (min-width: $sm) {
        display: inline;
        flex: content;
        padding-left: 35px !important;
        padding-right: 35px !important;
    }
}

.btn-salir {
    min-width: 120px;


    @media (min-width: $sm) {
        min-width: 140px;
    }

    @media (min-width: $lg) {
        min-width: 170px;
    }
}


.btn-flex3 {
    display: flex;

    @media (max-width: $sm) {
        flex: 1;
    }

    @media (min-width: $sm) {
        display: inline;
        flex: content !important;
    }

    @media (min-width: $lg) {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }
}

.card-tabla {
    border: none;
    border-radius: 0.625rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    background: linear-gradient(to top, $gris 0%, $gris 100%);
    padding: 1px;
}

.card-tabla .card-content-form {
    background-color: white;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    padding: 3.5rem;
    background-image: url('../assets/img/honda_logo.jpg');
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: bottom right;
    background-size: 12%;

    @media (max-width: $md) {
        padding: 1rem;
    }
}

.card-tabla .card-content {
    background-color: white;
    border-radius: 0.625rem;
}

.card-empty .card-content {
    background-color: white;
    border-radius: 0.625rem;
}
