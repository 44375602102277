table {
    width: 100%;
    table-layout: fixed;
}
table,
th,
td {
    border: 1px solid #ffffff;
    border-collapse: collapse;
}


@media screen and (min-width: 480px) {
    .column-resp1{
        width: 200px;
    }
}

@media screen and (max-width: 480px) {
    .column-resp{
        width: 110px;
    }
    .column-resp1{
        min-width: 190px !important;
    }
    .tabla {
        margin-left: 15px;
        overflow: auto;
        display: block;
        table-layout: auto;
    }
}

th,
td {
    /* padding: 5px; */
    text-align: left;
}
#t01 tr:nth-child(even) {
    background-color: #eee;
}
#t01 tr:nth-child(odd) {
    background-color: #fff;
}
.tc1 {
    background-color: #323232 !important;
    color: white;
    border-radius: 0.5rem 0.5rem;
}

#assigments {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#assigments td,
#assigments th {
    border: 1px solid #ddd;
    padding: 8px;
}

#assigments tr:nth-child(even) {
    background-color: #f2f2f2;
}

#assigments tr:hover {
    background-color: #ddd;
}

#assigments th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #323232;
    color: white;
}
