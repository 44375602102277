@import '../configs/color.css';

@page {
    margin: 30px;
    size: portrait;
}
@media screen {
    .no-mostrar {
        display: none !important;
    }
}
@media print {
    @page {
        margin: 30px;
        size: portrait;
    }

    body,
    html,
    html body {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        min-width: 0 !important;
        max-height: auto !important;
        background-color: #ffffff !important;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
    .hoja-carta {
        width: 21.6cm;
        height: 27.9cm;
        background-color: red;
    }
    .media-carta-arriba {
        max-width: 21.6cm;
        width: 21.6cm;
        max-height: 15.95cm;
        height: 15.95cm;
    }

    .media-carta-abajo {
        max-width: 21.6cm;
        width: 21.6cm;
        max-height: 11.95cm;
        height: 11.95cm;
    }

    .dont-print {
        display: none !important;
    }
    #PRINT-ROOT-CONTAINER {
        font-size: 11px;
        color: rgb(0, 0, 0);
        display: block !important;
    }
    .form-control[readonly] {
        background-color: #d41c1c !important;
    }

    .contenido-impresion {
        font-size: 14px !important;
    }
    .react-bs-table table td,
    .react-bs-table table th {
        /* overflow: hidden; */
        white-space: unset !important;
        text-overflow: unset;
    }

    .table {
        width: 100%;
        color: #ffffff !important;
    }
    .table td,
    .table th {
        border: none !important;
    }

    /* .react-bs-container-header {
        color: rgb(45, 199, 109) !important;
        background: #323232;
        background-color: #323232;
        padding: 1px !important;
        border-radius: 1rem 1rem;
    }

    .react-bs-table thead {
        padding: 0.2rem !important;
        color: rgb(190, 161, 32);
        background-color: #323232 !important;
    }

    .tr {
        background-color: #323232;
        color: rgb(231, 21, 21);
        border-radius: 0.5rem 0.5rem;
    } */

    .react-bs-container-header th {
        color: #ffffff;
        text-align: center !important;
        font-size: 14px;
        -webkit-print-color-adjust: exact;
    }

    .react-bs-table td {
        font-size: 12px !important;
    }

    .react-bs-table td span {
        font-size: 12px;
    }

    .impresion-horizontal {
        @page {
            margin: 30px;
            size: landscape;
        }
    }
}
#PRINT-ROOT-CONTAINER {
    display: none;
}
