/* Clases de colores para texto */

.blanco {
    color: white !important;
}

.azul48 {
    color: #487E95 !important;
}

.azul52 {
    color: #214252 !important;
}

.rojo {
    color: #d92027 !important;
}

.rojo0a {
    color: #cd0a0a !important;
}

.rojo45 {
    color: #cd4545 !important;
}

.rojo39 {
    color: #ef4339 !important;
}

.azul {
    color: #487e95 !important;
}

.verde {
    color: #7ea04d !important;
}

.negro {
    color: #000000 !important;
}

.gris {
    color: #888888 !important;
}

.negro32 {
    color: #323232 !important;
}

.grisd7 {
    color: #d7d7d7 !important;
}

/* Fondo de los colores */

.bg-blanco {
    background-color: white !important;
}

.bg-rojo {
    background: #d92027 !important;
}

.bg-rojo0a {
    background-color: #cd0a0a !important;
}

.bg-rojo45 {
    background-color: #cd4545 !important;
}

.bg-rojo39 {
    background-color: #ef4339 !important;
}

.bg-azul {
    background-color: #487e95 !important;
}

.bg-verde {
    background-color: #7ea04d !important;
}

.bg-negro {
    background-color: #000000 !important;
}

.bg-gris {
    background-color: #888888 !important;
}

.bg-negro32 {
    background-color: #323232 !important;
}

.bg-grisd7 {
    background-color: #d7d7d7 !important;
}

.login-overlay .panel-body {
    min-height: 244px;
}

.has-feedback {
    position: relative;
}

.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}

.login-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.card-login {
    border: 0;
    border-radius: 45px;
    -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-right: 15vw;
    padding: 30px 20px;
    padding: 100px 50px 75px 50px !important;
    width: 400px !important;
    background-color: white;
    overflow: hidden;
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@media (max-width: 768px) {

    .card-login {
        -webkit-animation: slideInRight 1.5s;
                animation: slideInRight 1.5s;
        margin-right: 0;
        padding: 75px 35px 54px 35px !important;
    }
}

@media (max-width: 576px) {

    .card-login {
        width: auto !important;
        margin-right: 25px;
        margin-left: 25px;
        padding: 50px 25px 37px 25px !important;
    }
}

.bienvenida {
    color: white;
    font-weight: bold;
}

.buttons-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.suscripcion-background {
    height: 105vh;
    background-image: url(../../../../../assets/img/background_image.png),
        -webkit-gradient(linear,
            left top, right top,
            from(#fafafa),
            color-stop(#f1f1f1),
            color-stop(#f9f9f9),
            color-stop(#e4e4e4),
            color-stop(#e9e9e9),
            color-stop(#e5e5e5),
            color-stop(#dedede),
            color-stop(#cecbcb),
            to(#c2c0c2));
    background-image: url(../../../../../assets/img/background_image.png),
        linear-gradient(to right,
            #fafafa,
            #f1f1f1,
            #f9f9f9,
            #e4e4e4,
            #e9e9e9,
            #e5e5e5,
            #dedede,
            #cecbcb,
            #c2c0c2);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.blue-gradient-bg {
    min-height: 100vh;
    background-image: url(../../../../../assets/img/background_image.png),
        -webkit-gradient(linear,
            left top, right top,
            from(#fafafa),
            color-stop(#f1f1f1),
            color-stop(#f9f9f9),
            color-stop(#e4e4e4),
            color-stop(#e9e9e9),
            color-stop(#e5e5e5),
            color-stop(#dedede),
            color-stop(#cecbcb),
            to(#c2c0c2));
    background-image: url(../../../../../assets/img/background_image.png),
        linear-gradient(to right,
            #fafafa,
            #f1f1f1,
            #f9f9f9,
            #e4e4e4,
            #e9e9e9,
            #e5e5e5,
            #dedede,
            #cecbcb,
            #c2c0c2);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@media screen and (max-width: 576px) and (orientation: landscape) {

    .blue-gradient-bg {
        height: auto !important;
        padding: 30px;
    }
}

.card-registro {
    border: 0;
    border-radius: 45px;
    -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 62.9%;
    padding: 40px 5% 54px 5% !important;
    background-color: white;
}

@media (max-width: 768px) {

    .card-registro {
        margin-right: 0;
        padding: 40px 3% 54px 3% !important;
    }
}

@media (max-width: 576px) {

    .card-registro {
        width: auto !important;
        margin-right: 25px;
        margin-left: 25px;
        padding: 50px 25px 37px 25px !important;
    }
}

.card-registro_cotizacion {
    border: 0;
    border-radius: 15px;
    -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 80%;
    padding: 20px 20px 30px 20px !important;
    background-color: white;
}

@media (max-width: 576px) {

    .card-registro_cotizacion {
        width: auto !important;
        margin-right: 25px;
        margin-left: 25px;
        padding: 50px 25px 37px 25px !important;
    }
}

.logo-registro {
    height: 22px;
}

@media (min-width: 576px) {

    .logo-registro {
        height: 26px;
    }
}

.circulo {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid black;
    background: white;
    z-index: 100;
    color: black;
    font-size: 15px !important;
}

.circulo-activo {
    background-color: #cd0a0a;
    z-index: 100;
    color: white;
    font-size: 15px !important;
}

.link {
    color: #cd4545;
}

.link:hover {
    color: #cd0a0a;
}

@-webkit-keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
